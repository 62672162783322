import $ from 'jquery' //import jquery module
import Swiper from 'swiper/bundle';
import responsiveImage from './libs/responsive-images.js';
import animatingScroll from './libs/animatingScroll';

const responsive = responsiveImage($)
const home = {};

home.herobannerFull = () => {
  let options = {};
  let setDuration = 5000
  if ($(".herobanner-full.swiper-full .swiper-wrapper .swiper-slide").length == 1 ) {
    options = {
      loop: false,
    }
    $('.herobanner-full.swiper-full .swiper-arrow').css('display', 'none');
  } else {
    options = {
      loop: true,
      speed: 1000,
      autoResize: true,
      effect: "fade",
      autoplay: {
        delay: setDuration,
        disableOnInteraction: false,
      },
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      on: {
        init: function () {
          // Get all slides in the Swiper
          let slides = this.slides;

          // Loop through the slides and find the first one that contains a video
          for (let i = 0; i < slides.length; i++) {
            let slide = slides[i];
            let video = slide.querySelector('video');

            if (video) {
              // If a video is found, disable autoplay for this slide
              this.autoplay.stop();
              // break;
            } else {
              this.autoplay.start();
            }
          }

          let currentVideo =  $("[data-swiper-slide-index=" + this.realIndex + "]").find("video");
              currentVideo.trigger('play');
        }
      },

      breakpoints: {
        320: {
          navigation: { enabled: false },
        },
        768: {
          navigation: { enabled: true },
        },
      },
    }
  }

  const swiper = new Swiper('.herobanner-full.swiper-full.swiper', options);
  let sliderVideos = $(".swiper-slide video");

  swiper.on('slideChange', function () {
     if($(`[data-swiper-slide-index="${this.realIndex}"]`).find("video").length){
      swiper.autoplay.stop();
    }
    else {
      swiper.autoplay.start();
    }

    /* stop all videos (currentTime buggy without this loop idea) */
    sliderVideos.each(function( index ) {
      this.currentTime = 0;
    });

    /* SWIPER GET CURRENT AND PREV SLIDE (AND VIDEO INSIDE) */
    let prevVideo =  $("[data-swiper-slide-index=" + this.previousIndex + "]").find("video"),
        currentVideo =  $("[data-swiper-slide-index=" + this.realIndex + "]").find("video");
        prevVideo.trigger('stop');
        currentVideo.trigger('play');

  });


  // swiper.on('slideChange', function () {
  //   $('.herobanner-full').removeClass('after-animate');

	// 	if ($(`[data-swiper-slide-index="${this.realIndex}"]`).find("video").length) {
  //     const videoElemnt = $(`[data-swiper-slide-index="${this.realIndex}"]`).find("video")[0]
  //     if(videoElemnt){
  //       videoElemnt.load();
  //       videoElemnt.onloadedmetadata = function() {
  //         swiper.params.autoplay.delay =videoElemnt.duration * 1000;
  //       }
  //     }

	// 	} else {
  //     swiper.params.autoplay.delay = setDuration;
	// 	}
  // })

  $('.scroll-down').on('click', function(e){
    e.preventDefault();
    var target = $($(this).attr('data-target'));
    if(target.length){
        var scrollTo = target.offset().top - $("header").outerHeight();
        $('body, html').animate({
            scrollTop: scrollTo+'px'
        }, 800);
    }
  });

}

home.responsiveBanner = () => {
  const elemBanner = $(".responsive figure img");
  responsive.init(elemBanner, {
    type: "image",
  });
};

home.hoverSectionServiceFocus = () => {
  const elem = $('.section-service-focus__list--item');
  elem.hover(function(){
    const data_hover = $(this).attr('data-hover');
    $(this).parents('.section-service-focus').find('.section-service-focus__bg[data-bg=' + data_hover +']').addClass('active');
  }, function(){
    const data_hover = $(this).attr('data-hover');
    $(this).parents('.section-service-focus').find('.section-service-focus__bg[data-bg=' + data_hover +']').removeClass('active');
  });
}

home.init = function(){
  animatingScroll().animating('.animating-scroll')
}

home.init()

// install herobanner
window.onload = () => {
	// heroBanner($).init($(".herobanner-full.swiper"))
	home.herobannerFull()
  home.responsiveBanner()
  home.hoverSectionServiceFocus()
}
