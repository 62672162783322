export default () => ({
  animating(element) {
    const el = document.querySelectorAll(element)
    for (let i = 0; i < el.length; i++) {
      let elAnimate = el[i]
      let trigger = elAnimate.getAttribute('data-animate-trigger')
      const animateTrigger = document.querySelector(trigger)
      elAnimate.classList.add('before-animate')

      const f = () => {
        // handleScroll
        const heightWindow = window.innerHeight
        const offsetTop = animateTrigger.getBoundingClientRect().top

        if (offsetTop - heightWindow < 0) {
          window.removeEventListener('scroll', f)
          elAnimate.classList.add('after-animate')
        }

      }
      window.addEventListener('load', f)
      window.addEventListener('scroll', f)
    }
  }
})
